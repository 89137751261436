
import { Component, Vue, Prop } from 'vue-property-decorator';
import tasqsListModule from '@/store/modules/tasqsListModule';
import accountModule from '@/store/modules/accountModule';

@Component
export default class ActionBubble extends Vue {
  get getBatchEditResponseDisabledMsg() {
    return tasqsListModule.batchResponseDisabledMsg;
  }

  get showErrorMsgTooltip() {
    return !this.respondEnabled && tasqsListModule.checkedTasqs.length > 0;
  }

  async batchRespond() {
    if (this.respondEnabled) {
      tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
          tasqsListModule.setIsBatchResponding(true);
    }
  }

  get selectedEditItemsCount() {
    return tasqsListModule.checkedTasqs.length;
  }

  get respondEnabled() {
    return tasqsListModule.batchResponseEnabled;
  }

  async batchReassign() {
    if (this.respondEnabled) {
      tasqsListModule.setIsBatchReassign(true);
      accountModule.getReassignmentList({ useExisting: true });
      this.$emit('batch-reassign-clicked')
    }
  }

  async batchWait() {
    if (this.respondEnabled) {
      tasqsListModule.setIsBatchReassign(true);
      this.$emit('batch-wait-clicked')
    }
  }

  closeEditToolbar () {
    this.$emit('edit-toolbar-closed')
  }
}
